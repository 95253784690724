/**
 * Форма смены почты
 */
import { disableButton, enableButton } from '@commonJs/utils';

$(document).on('submit', '#change-email-form', function (e) {
    e.preventDefault();
    const targetForm = $(this);
    const button = targetForm.find('button');

    $.ajax({
        type: 'POST',
        url: targetForm.attr('action'),
        data: targetForm.serialize(),
        beforeSend: function () {
            disableButton(button);
        },
        success: function (response) {
            showErrors(targetForm, {});
            $('#inputNewEmail').val();

            if ($('#verification-alert').length) {
                $('#verification-alert b').text(response.email);
            } else {
                $('#actions').prepend(getVerificationBlock(response));
            }

            targetForm.append(
                '<div id="success-email-connection" class="mt-3 alert alert-success small" role="alert"><p class="m-0">' +
                    response.message +
                    '</p></div>'
            );
        },
        error: function (errors) {
            showErrors(targetForm, JSON.parse(errors.responseText));
        },
        complete: function () {
            enableButton(button);
        },
    });
});
