$(document).on('click', '[data-js=logout]', function (e) {
    e.preventDefault();
    $.ajax({
        type: 'POST',
        url: '/portal/api/logout',
        success: function (response) {
            location.href = response.redirectUri;
        },
        error: function () {
            console.log('error');
        },
    });
});
