/**
 * Обработчик формы регистрации
 */
import { disableButton, enableButton } from '@commonJs/utils';

$(document).on('submit', '.registration-form', function (e) {
    e.preventDefault();

    const targetForm = $(this);
    const button = targetForm.find('button');

    $.ajax({
        type: 'POST',
        url: targetForm.attr('action'),
        data: targetForm.serialize(),
        beforeSend: function () {
            disableButton(button);
        },
        success: function (response) {
            showErrors(targetForm, {});
            PushDataToGtm({
                event: 'CompleteRegistration',
                game_player_id: response.playerId,
                social_player_id: response.socialId,
                new_player: response.isNewPlayer,
            });

            location.href = response.redirectUri;
        },
        error: function (errors) {
            showErrors(targetForm, JSON.parse(errors.responseText));
        },
        complete: function () {
            enableButton(button);
        },
    });
});
