/**
 * Форма смены пароля
 */
import { disableButton, enableButton } from '@commonJs/utils';

const clearForm = () => {
    $('#input-name-old-password').val('');
    $('#input-name-new-password').val('');
    $('#input-name-repeat-new-password').val('');
};

$(document).on('submit', '#change_password_form', function (e) {
    e.preventDefault();
    const targetForm = $(this);
    const button = targetForm.find('button');

    $.ajax({
        type: 'POST',
        url: targetForm.attr('action'),
        data: targetForm.serialize(),
        beforeSend: function () {
            disableButton(button);
        },
        success: function (response) {
            showErrors(targetForm, {});

            targetForm.append(
                `<div
                    id="success-email-connection"
                    class="mt-3 alert alert-success small"
                    role="alert">
                    <p class="m-0">${response.message}</p>
                </div>`
            );
            clearForm();
        },
        error: function (errors) {
            let res = JSON.parse(errors.responseText);
            showErrors(targetForm, res);
        },
        complete: function () {
            enableButton(button);
        },
    });
});
