/**
 * Чекбокс подписки на email рассылку
 */
const changeEmailNotificationSubscription = function (e) {
    const subscriptionStatus = $(this).is(':checked');
    const checkbox = $(this);

    $.ajax({
        type: 'POST',
        url: e.data.uri,
        data: {
            subscriptionStatus,
        },
        beforeSend: function () {
            checkbox.attr('disabled', true);
        },
        success: function (data) {},
        error: function (errors) {
            checkbox.prop('checked', !subscriptionStatus);
            console.log('changeEmailNotificationSubscription error', errors);
        },
        complete: function () {
            checkbox.removeAttr('disabled');
        },
    });
};

export default changeEmailNotificationSubscription;
