import disconnectAuthNetworkAction from '@commonJs/actions/disconnectAuthNetworkAction';

const emailDisconnectCallback = (button, response) => {
    button.attr('id', 'add-email');
    $('#actions').slideUp(300, function () {
        $(this).remove();
    });

    $('#add-email-block').slideUp();
    $('#change-email-block').slideUp();
    $('#change-password-block').slideUp();

    $('#email-connection .user-email').text(response.emailStatusText);
};

const networkDisconnectCallback = (button, response) => {
    const connectionStatus = button.closest('.connection-block').find('.connection-status');
    connectionStatus.text(response.connectionStatusText);
    connectionStatus.toggleClass(['muted', 'font-weight-bolder']);

    const connectLink = $('<a class="btn btn-outline-primary"></a>');
    connectLink.attr('href', response.urlAuth);
    connectLink.text(response.addLinkText);

    button.after(connectLink);
    button.remove();
};

disconnectAuthNetworkAction(
    '#facebook-binding-remove',
    '/portal/api/binding-remove/facebook',
    networkDisconnectCallback
);

disconnectAuthNetworkAction(
    '#google-binding-remove',
    '/portal/api/binding-remove/google',
    networkDisconnectCallback
);

disconnectAuthNetworkAction(
    '#microsoft-binding-remove',
    '/portal/api/binding-remove/microsoft',
    networkDisconnectCallback
);

disconnectAuthNetworkAction(
    '#email-binding-remove',
    '/portal/api/binding-remove/email',
    emailDisconnectCallback
);
