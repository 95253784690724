const disableButton = (button) => {
    const spinner = button.find('.spinner-grow');
    button.attr('disabled', 'disabled');
    spinner.toggleClass('d-inline-block');
};

const enableButton = (button) => {
    const spinner = button.find('.spinner-grow');
    button.removeAttr('disabled');
    spinner.toggleClass('d-inline-block');
};

export { disableButton, enableButton };
