const updateUserLanguageAction = (data) => {
    $.ajax({
        type: 'POST',
        url: '/portal/api/setUserLanguage',
        data,
        success: function (response) {
            location.href = response.path ? response.path : '/' + data.lang;
        },
        error: function (errors) {
            console.error(errors);
        },
    });
};

$(document).on('click', '#portal-game #lang button', function (e) {
    e.preventDefault();
    const lang = $(this).data('lang');
    const data = { lang: lang, path: window.location.pathname };
    updateUserLanguageAction(data);
});
