/**
 * Форма смены имени
 */
import { disableButton, enableButton } from '@commonJs/utils';

$(document).on('click', '#save-name', function () {
    const targetForm = $('#name_form');
    const button = targetForm.find('button');

    $.ajax({
        type: 'POST',
        url: targetForm.attr('action'),
        data: targetForm.serialize(),
        beforeSend: function () {
            disableButton(button);
        },
        success: function (response) {
            showErrors(targetForm, {});
            $('#success-change-name').remove();
            $('#reset-form-name').attr('data-name', $('#user-name').text().trim());
            $('#user-name, #inputName2').toggle(0);
            $('#name-form-buttons > div').toggleClass('d-none');
            targetForm.append(
                '<div id="success-change-name" class="mt-3 alert alert-success small" role="alert"><p class="m-0">' +
                    response.messages[0] +
                    '</p></div>'
            );
        },
        error: function (errors) {
            $('#success-change-name').remove();
            let res = JSON.parse(errors.responseText);
            showErrors(targetForm, res);
        },
        complete: function () {
            enableButton(button);
        },
    });
});
