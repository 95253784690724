/**
 * Форма сброса пароля
 */
$(document).on('submit', '.reset-pass-form', function (e) {
    let targetForm = $(this);
    $.ajax({
        type: 'POST',
        url: targetForm.attr('action'),
        data: targetForm.serialize(),
        success: function (data) {
            showErrors(targetForm, {});
            location.href = data.redirectUrl;
        },
        error: function (errors) {
            showErrors(targetForm, JSON.parse(errors.responseText));
        },
    });
    e.preventDefault();
});
