import '../scss/index.scss';

import '@commonJs/forms/loginForm';
import '@commonJs/forms/forgotForm';
import '@commonJs/forms/twoStepRegistrationForm';
import '@commonJs/forms/changeEmailForm';
import '@commonJs/forms/changePasswordForm';
import '@commonJs/forms/changeNameForm';
import '@commonJs/forms/resetPasswordForm';
import '@commonJs/forms/registrationForm';
import '@commonJs/actions/xsollaWidgetCloseAction';

import '../js/logout';
import '../js/resendVerificationEmailListener';
import '../js/handlers/changeEmailNotificationSubscriptionHandler';
import '../js/handlers/resetPasswordPageHandler';
import '../js/handlers/disconnectAuthNetworkHandler';
import '../js/handlers/trackModalHandler';
import '../js/handlers/updateUserLanguageHandler';
import '../js/handlers/socialLoginHandlers';
import '../js/handlers/agreementsHandler';
import '../js/handlers/acceptAgreementsCheckboxHandler';
import '../js/forms/createDummyProfileForm';
