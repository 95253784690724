/**
 * Обработчик формы двухшаговой авторизации
 */
import { disableButton, enableButton } from '@commonJs/utils';

const targetForm = $('#signup-form-reg-step-01');

const twoStepRegistrationFormMoveToStepTwo = () => {
    const email = $('#signup-email-reg-step-01').val();
    const csrfName = $('#signup-form-reg-step-01 [name="csrf_name"]').val();
    const csrfValue = $('#signup-form-reg-step-01 [name="csrf_value"]').val();
    const url = targetForm.attr('action');

    checkEmailForRegistration(email, csrfName, csrfValue, url).then(
        () => {
            $('#reg_step_01').hide();
            $('#reg_step_02').show();
            $('#signup-email-reg-step-02').val(email);
        },
        (err) => {
            showErrors(targetForm, err);
        }
    );
};

const twoStepRegistrationFormMoveBackToStepOne = () => {
    $('#reg_step_02').hide();
    $('#reg_step_01').show();
};

const checkEmailForRegistration = (email, csrfName, csrfValue, url) => {
    return new Promise((resolve, reject) => {
        const button = targetForm.find('button');

        $.ajax({
            type: 'POST',
            url: url,
            data: { email: email, csrf_name: csrfName, csrf_value: csrfValue },
            beforeSend: function () {
                disableButton(button);
            },
            success: resolve,
            error: function (response) {
                reject(JSON.parse(response.responseText));
            },
            complete: function () {
                enableButton(button);
            },
        });
    });
};

$(document).ready(function () {
    /* Форма в 2 шага: переход ко второму шагу */
    targetForm.on('submit', function (e) {
        e.preventDefault();
        twoStepRegistrationFormMoveToStepTwo();
    });

    /* Форма в 2 шага: возврат на первый шаг */
    $('.js-step-two-back').on('click', function (e) {
        e.preventDefault();
        twoStepRegistrationFormMoveBackToStepOne();
    });
});

$(document).on('submit', '.registration-2-step-form', function (e) {
    e.preventDefault();
    const targetForm = $(this);
    const button = targetForm.find('button');

    $.ajax({
        type: 'POST',
        url: targetForm.attr('action'),
        data: targetForm.serialize(),
        beforeSend: function () {
            disableButton(button);
        },
        success: function (data) {
            showErrors(targetForm, {});
            PushDataToGtm({
                event: 'CompleteRegistration',
                game_player_id: data.playerId,
                social_player_id: data.socialId,
                new_player: data.isNewPlayer,
            });
            location.href = data.redirectUri;
        },
        error: function (errors) {
            showErrors(targetForm, JSON.parse(errors.responseText));
        },
        complete: function () {
            enableButton(button);
        },
    });
});
