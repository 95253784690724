const PORTAL_ACCEPT_AGREEMENTS = 'PORTAL_ACCEPT_AGREEMENTS';
const PORTAL_ACCEPT_AGREEMENTS_URL = '/';
const CRAZY_GAMES_ACCEPT_AGREEMENTS = 'CRAZY_GAMES_ACCEPT_AGREEMENTS';
const CRAZY_GAMES_ACCEPT_AGREEMENTS_URL = '/crazygames';
const MS_START_ACCEPT_AGREEMENTS = 'MS_START_ACCEPT_AGREEMENTS';
const MS_START_ACCEPT_AGREEMENTS_URL = '/msstart';

export {
    PORTAL_ACCEPT_AGREEMENTS,
    CRAZY_GAMES_ACCEPT_AGREEMENTS,
    MS_START_ACCEPT_AGREEMENTS,
    PORTAL_ACCEPT_AGREEMENTS_URL,
    CRAZY_GAMES_ACCEPT_AGREEMENTS_URL,
    MS_START_ACCEPT_AGREEMENTS_URL,
};
