import loginWithFacebookPopupAction from '@commonJs/actions/loginWithFacebookPopupAction';
import handleFbPopupLoginAction from '@commonJs/actions/handleFbPopupLoginAction';
import {
    loginWithFacebookAction,
    loginWithGoogleAction,
    loginWithMicrosoftAction,
} from '../actions/socialLoginActions';
import { PORTAL_ACCEPT_AGREEMENTS } from '@commonJs/cookies';
import Cookies from 'js-cookie';

const ACCEPT_AGREEMENTS_COOKIE = PORTAL_ACCEPT_AGREEMENTS;

$(document).on('click', '[data-js="fb-popup-btn"]', () => {
    if (!Cookies.get(ACCEPT_AGREEMENTS_COOKIE)) {
        $('[data-js="accept-agreement"]').data('target', 'fb-popup');
        $('#agreement').modal('show');
    } else {
        loginWithFacebookPopupAction(handleFbPopupLoginAction, '/facebook');
    }
});

$('.facebook-link').on('click', function () {
    if (
        !Cookies.get(ACCEPT_AGREEMENTS_COOKIE) &&
        $('#signup-terms-checkbox-reg-step-01').is(':checked') === false
    ) {
        $('[data-js="accept-agreement"]').data('target', 'fb');
        $('#agreement').modal('show');
    } else {
        loginWithFacebookAction();
    }
});

$('.google-link').on('click', function () {
    if (
        !Cookies.get(ACCEPT_AGREEMENTS_COOKIE) &&
        $('#signup-terms-checkbox-reg-step-01').is(':checked') === false
    ) {
        $('[data-js="accept-agreement"]').data('target', 'ga');
        $('#agreement').modal('show');
    } else {
        loginWithGoogleAction();
    }
});

$('.microsoft-link').on('click', function () {
    if (
        !Cookies.get(ACCEPT_AGREEMENTS_COOKIE) &&
        $('#signup-terms-checkbox-reg-step-01').is(':checked') === false
    ) {
        $('[data-js="accept-agreement"]').data('target', 'ms');
        $('#agreement').modal('show');
    } else {
        loginWithMicrosoftAction();
    }
});
