/**
 * Повторная отправка письма для верификации email
 */
import { disableButton, enableButton } from '@commonJs/utils';

const resendVerificationEmail = function (e) {
    const button = $(this);

    $.ajax({
        type: 'POST',
        url: e.data.uri,
        beforeSend: function () {
            disableButton(button);
        },
        success: function (response) {
            button.after(`<p class="message smallest mt-2 mb-0">${response.message}</p>`);
        },
        error: (errors) => console.error('error', errors),
        complete: function () {
            enableButton(button);
        },
    });
};

export default resendVerificationEmail;
