const loginWithFacebookAction = () => {
    window.location.assign($('.facebook-link').data('url'));
};

const loginWithMicrosoftAction = () => {
    window.location.assign($('.microsoft-link').data('url'));
};

const loginWithGoogleAction = () => {
    window.location.assign($('.google-link').data('url'));
};

export { loginWithFacebookAction, loginWithMicrosoftAction, loginWithGoogleAction };
