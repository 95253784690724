import Cookies from 'js-cookie';
import { MS_START_ACCEPT_AGREEMENTS, MS_START_ACCEPT_AGREEMENTS_URL } from '@commonJs/cookies';

const ACCEPT_AGREEMENTS_COOKIE = MS_START_ACCEPT_AGREEMENTS;

$('#signup-terms-checkbox-reg-step-01').on('change', function () {
    if ($(this).is(':checked') && !Cookies.get(ACCEPT_AGREEMENTS_COOKIE)) {
        Cookies.set(ACCEPT_AGREEMENTS_COOKIE, 1, {
            expires: 365 * 5, // дней
            path: MS_START_ACCEPT_AGREEMENTS_URL,
            secure: true,
            sameSite: 'none',
        });
    }

    if (!$(this).is(':checked')) {
        Cookies.remove(ACCEPT_AGREEMENTS_COOKIE);
    }
});
