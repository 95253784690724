// TODO вынести проверку параметра code нполностью на сервер
const resetPasswordPageAction = (checkUrl) => {
    const url = new URL(window.location.href);

    if (url.searchParams.get('sc_code') && url.searchParams.get('sc_code') !== '') {
        $.ajax({
            type: 'POST',
            url: checkUrl,
            data: {
                sc_code: url.searchParams.get('sc_code'),
            },
            success: function (data) {
                $('#resetPass').modal('show');
            },
            error: function (errors) {
                const res = JSON.parse(errors.responseText);
                const text = res.errors.sc_code[0];
                $('#resetPass .modal-body').html('<p class="signin">' + text + '</p>');
                $('#resetPass').modal('show');
            },
        });
    }
};

export default resetPasswordPageAction;
