/**
 * Форма создания профиля пустышки
 */
(() => {
    const timePicker = jQuery('#time-machine-datetimepicker');

    timePicker.datetimepicker({
        parentID: '#dummy-time-container',
        id: 'dummy-datetime',
    });

    const form = document.getElementById('create-dummy-profile-form');

    if (form !== null) {
        const firstLevelCashValue = 7;
        const firstLevelMoneyValue = 20000;

        const uri = form.getAttribute('action');
        const method = form.getAttribute('method');

        const dummyLevelInput = document.getElementById('dummy-level');
        const dummyCashInput = document.getElementById('dummy-cash');
        const dummyMoneyInput = document.getElementById('dummy-money');
        const dummySeasonCurrencyInput = document.getElementById('dummy-season-currency-count');

        const defaultLevelFormValue = dummyLevelInput.value;
        const defaultCashFormValue = dummyCashInput.value;
        const defaultMoneyFormValue = dummyMoneyInput.value;
        const defaultSeasonCurrencyFormValue = dummySeasonCurrencyInput.value;

        const submitForm = (e) => {
            e.preventDefault();

            const body = new FormData(form);
            const button = form.querySelector('button');
            button.setAttribute('disabled', true);

            fetch(uri, { method, body })
                .then((response) => response.json())
                .then((data) => {
                    if (!data.success) {
                        alert(`Error: ${data.message}`);
                        return;
                    }

                    window.location = data.redirectUri;
                })
                .catch((error) => alert(error))
                .finally(() => button.removeAttribute('disabled'));
        };

        const setValuesAndAttributesByLevel = (inputValue) => {
            if (parseInt(inputValue, 10) === 1) {
                dummyCashInput.setAttribute('readonly', true);
                dummyMoneyInput.setAttribute('readonly', true);

                dummyCashInput.value = firstLevelCashValue;
                dummyMoneyInput.value = firstLevelMoneyValue;
                return;
            }

            if (dummyCashInput.hasAttribute('readonly')) {
                dummyCashInput.removeAttribute('readonly');
            }

            if (dummyMoneyInput.hasAttribute('readonly')) {
                dummyMoneyInput.removeAttribute('readonly');
            }

            dummyCashInput.value = defaultCashFormValue;
            dummyMoneyInput.value = defaultMoneyFormValue;
        };

        const validateValue = (input) => {
            const max = parseInt(input.getAttribute('max'), 10);
            const min = parseInt(input.getAttribute('min'), 10);
            const inputValue = parseInt(input.value, 10);

            if (input.value !== '' && inputValue !== 0 && inputValue < min) {
                input.value = Math.abs(inputValue);
            }

            if (inputValue > max) {
                alert(`Значение не может быть больше ${max}`);
                input.value = max;
            }

            if (
                input.value.length !== parseInt(input.value, 10).toString().length ||
                isNaN(parseInt(input.value, 10)) === true
            ) {
                let val = input.value;
                for (let i = 0; i < input.value.length; i++) {
                    if (isNaN(input.value[i])) {
                        val = input.value.substring(0, i) + input.value.substring(i + 1);
                        continue;
                    }
                }

                input.value = val;
            }

            if (input.value[0] === '0' && input.value.length > 1) {
                let val = input.value;
                for (let i = 0; i < input.value.length; i++) {
                    if (input.value[i] === '0') {
                        val = input.value.substring(i + 1);
                        continue;
                    }

                    break;
                }

                if (val === '') {
                    val = 0;
                }

                input.value = val;
            }
        };

        form.addEventListener('submit', (e) => submitForm(e));

        dummyLevelInput.addEventListener('change', function () {
            setValuesAndAttributesByLevel(this.value);
        });

        dummyLevelInput.addEventListener('input', function () {
            setValuesAndAttributesByLevel(this.value);
            validateValue(this);
        });
        dummyMoneyInput.addEventListener('input', function () {
            validateValue(this);
        });
        dummyCashInput.addEventListener('input', function () {
            validateValue(this);
        });
        dummySeasonCurrencyInput.addEventListener('input', function () {
            validateValue(this);
        });

        dummyLevelInput.addEventListener('blur', function () {
            if (this.value === '' || parseInt(this.value, 10) === 0) {
                this.value = defaultLevelFormValue;
                dummyCashInput.value = defaultCashFormValue;
                dummyMoneyInput.value = defaultMoneyFormValue;
            }
        });
        dummyMoneyInput.addEventListener('blur', function () {
            if (this.value === '') {
                this.value = defaultMoneyFormValue;
            }
        });
        dummyCashInput.addEventListener('blur', function () {
            if (this.value === '') {
                this.value = defaultCashFormValue;
            }
        });
        dummySeasonCurrencyInput.addEventListener('blur', function () {
            if (this.value === '') {
                this.value = defaultSeasonCurrencyFormValue;
            }
        });

        document
            .getElementById('dummy-season-currency-item-id')
            .addEventListener('change', function () {
                dummySeasonCurrencyInput.value = defaultSeasonCurrencyFormValue;
                dummySeasonCurrencyInput.setAttribute(
                    'max',
                    this.options[this.selectedIndex].dataset.limit
                );
            });
    }
})();
