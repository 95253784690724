/**
 * Отвязка способов авторизации в профиле
 */
import { disableButton, enableButton } from '@commonJs/utils';

const disconnectAuthNetworkAction = (selector, actionUrl, callback) => {
    $(document).on('click', selector, function () {
        const button = $(this);

        $.ajax({
            url: actionUrl,
            type: 'POST',
            data: {
                platformPlayerId: $('#platform-player-id').text(),
            },
            beforeSend: function () {
                disableButton(button);
            },
            success: function (response) {
                const parent = button.closest('.connection-block');
                parent.removeClass('connect');

                callback(button, response);

                if (parent.attr('id') === 'email-connection') {
                    $('#email-connection-forms').removeClass('connect');
                }

                const accountBlock = $('#account');
                let connectionsCount = accountBlock.attr('data-connections');

                connectionsCount--;

                accountBlock.attr('data-connections', connectionsCount);

                button
                    .removeClass('btn-outline-secondary')
                    .addClass('btn-outline-primary')
                    .text(response.addButtonText);
            },
            error: function (request, status) {
                alert('Error: ' + status);
            },
            complete: function () {
                enableButton(button);
            },
        });
    });
};

export default disconnectAuthNetworkAction;
