/**
 * Обработчик формы входа в игру
 */
import { disableButton, enableButton } from '@commonJs/utils';

$(document).on('submit', '.login-form', function (e) {
    e.preventDefault();
    const targetForm = $(this);
    const button = targetForm.find('button');

    $.ajax({
        type: 'POST',
        url: targetForm.attr('action'),
        data: targetForm.serialize(),
        beforeSend: function () {
            disableButton(button);
        },
        success: function (response) {
            showErrors(targetForm, {});
            location.href = response.redirectUri;
        },
        error: function (errors) {
            showErrors(targetForm, JSON.parse(errors.responseText));
        },
        complete: function () {
            enableButton(button);
        },
    });
});
