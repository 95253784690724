window.addEventListener('message', (event) => {
    let eventData = null;
    try {
        if (typeof event === 'object' && event !== null) {
            eventData = JSON.parse(event.data);
        }
    } catch {
        eventData = null;
    }

    if (typeof eventData === 'object' && eventData !== null && eventData.command === 'return') {
        document.querySelector('.xpaystation-widget-lightbox').remove();
    }
});
