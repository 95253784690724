import trackModal from '@commonJs/actions/trackModalAction';

const trackModalUrl = '/portal/api/trackModalStatus';

$('.modal').on('show.bs.modal', function () {
    trackModal('show', $(this).find('input[name=form]').val(), trackModalUrl);
});

$('.modal').on('hide.bs.modal', function () {
    trackModal('hide', $(this).find('input[name=form]').val(), trackModalUrl);
});

$(document).on('click', '#change-password', function () {
    if ($('#change-email-block').is(':visible')) {
        trackModal('hide', $('#change-email-block').find('input[name=form]').val(), trackModalUrl);
    }

    $('#change-email-block').slideUp(300, function () {
        $('#change-password-block').slideToggle(300, function () {
            if ($(this).is(':visible')) {
                trackModal('show', $(this).find('input[name=form]').val(), trackModalUrl);
            } else {
                trackModal('hide', $(this).find('input[name=form]').val(), trackModalUrl);
            }
        });
    });
});

$(document).on('click', '#add-email', function () {
    $(this).toggleClass('open');

    $('#add-email-block').slideToggle(300, function () {
        if ($(this).is(':visible')) {
            trackModal('show', $(this).find('input[name=form]').val(), trackModalUrl);
        } else {
            trackModal('hide', $(this).find('input[name=form]').val(), trackModalUrl);
        }
    });
});

$(document).on('click', '#change-email', function () {
    if ($('#change-password-block').is(':visible')) {
        trackModal('hide', $('#change-password-block').find('input[name=form]').val());
    }

    $('#change-password-block').slideUp(300, function () {
        $('#change-email-block').slideToggle(300, function () {
            if ($(this).is(':visible')) {
                trackModal('show', $(this).find('input[name=form]').val(), trackModalUrl);
            } else {
                trackModal('hide', $(this).find('input[name=form]').val(), trackModalUrl);
            }
        });
    });
});
