/**
 * Функция выполняет логин в игру через фейсбук и всплывающее окно.
 *
 * Нужна для того, чтобы провести A/B-тестирование для трекинга маркетинговых метрик,
 * в частности - страницы перехода. Когда игрок заходит через отдельную вкладку фейсбука,
 * то фейсбук становится истоником перехода, затирая оригинальный источник перехода.
 * Вход через всплывающее окно должен решить эту проблему.
 */

const loginWithFacebookPopupAction = (handleLogin, uri) => {
    const rejectLogin = () => console.error('no login');

    FB.getLoginStatus(function (response) {
        if (response.status === 'connected') {
            handleLogin(response, uri);
        } else {
            FB.login(
                function (response) {
                    if (response.status === 'connected') {
                        handleLogin(response, uri);
                    } else {
                        rejectLogin();
                    }
                },
                { scope: 'email' }
            );
        }
    });
};

export default loginWithFacebookPopupAction;
