const trackModal = (event, form, url) => {
    console.log('trackModal', event, form);
    if (form !== undefined) {
        $.ajax({
            type: 'POST',
            url,
            data: 'event=' + event + '&form=' + form,
            success: function (data) {},
            error: function (errors) {
                console.log('error');
            },
        });
    }
};

export default trackModal;
