import loginWithFacebookPopupAction from '@commonJs/actions/loginWithFacebookPopupAction';
import handleFbPopupLoginAction from '@commonJs/actions/handleFbPopupLoginAction';
import {
    loginWithFacebookAction,
    loginWithGoogleAction,
    loginWithMicrosoftAction,
} from '../actions/socialLoginActions';
import Cookies from 'js-cookie';
import { PORTAL_ACCEPT_AGREEMENTS, PORTAL_ACCEPT_AGREEMENTS_URL } from '@commonJs/cookies';

const ACCEPT_AGREEMENTS_COOKIE = PORTAL_ACCEPT_AGREEMENTS;

$(document).ready(function () {
    if (Cookies.get(ACCEPT_AGREEMENTS_COOKIE)) {
        $('#signup-terms-checkbox-reg-step-01').prop('checked', true);
    }
});

$(document).on('click', '[data-js="accept-agreement"]', function () {
    Cookies.set(ACCEPT_AGREEMENTS_COOKIE, 1, {
        expires: 365 * 5, // дней
        path: PORTAL_ACCEPT_AGREEMENTS_URL,
    });
    console.log('click', $(this).data('target'));
    $('#signup-terms-checkbox-reg-step-01').prop('checked', true);
    $('#agreement').modal('hide');
    switch ($(this).data('target')) {
        case 'fb':
            loginWithFacebookAction();
            break;
        case 'ga':
            loginWithGoogleAction();
            break;
        case 'ms':
            loginWithMicrosoftAction();
            break;
        case 'fb-popup':
            loginWithFacebookPopupAction(handleFbPopupLoginAction, '/facebook');
            break;
    }
});

export { ACCEPT_AGREEMENTS_COOKIE };
